import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useRouter } from 'next/router';
import Head from 'next/head';
import { isExternalVisit } from '../../shared/route';
import { TopBarProps } from './TopBar.config';


const TopBar: React.FC<TopBarProps> = ({ pageName, textComponent, destination, children }) => {

  const router = useRouter();
  const isExternal = isExternalVisit();

  const handleGoBackClick = () => {
    // If destination prop is provided, default external control will not be executed
    destination ? router.push(destination) : isExternal ? router.push('/') : router.back();
  }
  return (
    <>
      <Head>
        {children}
      </Head>
      <Box px={2} sx={{ boxShadow: "0px 4px 0px #000000" }}>
        <Grid container alignItems={"center"} height={70}>
          <Box position={"absolute"} display={"flex"} alignItems={"center"}>
            <ExpandMoreIcon onClick={handleGoBackClick} />
          </Box>
          <Grid item container justifyContent={"center"}>
            {textComponent || <Typography variant='customBodyStrongCondensed'>{pageName}</Typography>}
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default TopBar