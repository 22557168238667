import styles from './defaultAnchor.module.scss'

import React from "react";
import Link from 'next/link';

const DefaultAnchor: React.FC<{ href: string }> = ({ children, href, ...props }) => {
    return (
        <Link href={href} legacyBehavior prefetch={false}>
            <a {...props} className={styles.link}>
                {children}
            </a>
        </Link>

    );
}

export default DefaultAnchor;
