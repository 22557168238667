import { Box, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import { useRouter } from 'next/router'
import { View3DIcon } from '../../assets/icons/icons'
import { CustomLightButtonNoShadow } from '../MuiStyledItems/CustomButtons'
import DefaultAnchor from '../Link/anchor/defaultAnchor'
import { ProductCardProps } from './ProductCard.config'

const ProductCard = (props: ProductCardProps) => {
  let router = useRouter();
  const { category, brandId } = router.query;

  const handle3DViewClick = () => {
    let url = '';
    if (brandId) {
      url = `/brands/${props.variant_model.brand_detail[0].brand_slug}/${props.variant_model.base_model_detail[0].model_slug}/${props.variant_model.variant_model_slug}`;
    }
    if (category) {
      url = `/${category.toString().toLowerCase()}/${props.variant_model.brand_detail[0].brand_slug}/${props.variant_model.base_model_detail[0].model_slug}/${props.variant_model.variant_model_slug}`;
    } else {
      url = `/${props.variant_model.category.toLowerCase()}/${props.variant_model.brand_detail[0].brand_slug}/${props.variant_model.base_model_detail[0].model_slug}/${props.variant_model.variant_model_slug}`;
    }
    return url;
  }

  return (
    <DefaultAnchor href={handle3DViewClick()}>
      <Box width={167} height={202} bgcolor={"#ffffff"} borderRadius={"8px"} border={"1px solid #000000"} boxShadow={"0px 6px 0px #000000"} py={2} sx={{ cursor: "pointer" }} onClick={handle3DViewClick}>
        <Grid container direction={"column"} alignItems={"center"} justifyContent={"center"}>
          <Box width={100} height={80} display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <img width={"100%"} style={{ maxHeight: '105px', width: 'auto' }} src={props.variant_model.thumbnail_file[0].url}></img>
          </Box>
          <Stack p={"8px"} width={"100%"}>
            <Typography noWrap align='center' variant='customBodyStrongCondensed'>{props.variant_model.brand_detail[0].brand_name}</Typography>
            <Typography noWrap align='center' variant='customBodyCondensed'>{props.variant_model.base_model_detail[0].model_name}</Typography>
          </Stack>
          <CustomLightButtonNoShadow onClick={handle3DViewClick} sx={{ height: "32px" }}> <View3DIcon style={{ marginRight: "8px" }} /> 3d view</CustomLightButtonNoShadow>
        </Grid>
      </Box>
    </DefaultAnchor>
  )
}

export default ProductCard