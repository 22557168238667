import { styled } from '@mui/material/styles';
import { Button, IconButton, Link } from '@mui/material';
import { theme } from '../../shared/theme';

export const CustomLightButton = styled(Button)({
    boxShadow: "0px 4px 0px #000000",
    border: "1px solid black",
    color: "black",
    background:"white !important",
    '&:active': {
        boxShadow: "none",
    },
    '&:focus': {
        border: "1px solid black",
    },
})

export const CustomLightButtonNoShadow = styled(Button)({
    border: "1px solid black",
    color: "black",
    background:"white !important",
    '&:active': {
        boxShadow: "none",
    },
    '&:focus': {
        border: "1px solid black",
    },
})

export const CustomYellowButtonNoShadow = styled(Link)({
    cursor: 'pointer',
    fontFamily: 'Crystal-CondensedBold',
    fontWeight: 700,
    textDecoration: 'none',
    fontSize: '16px',
    textTransform: 'uppercase',
    border: "1px solid black",
    borderRadius: '8px',
    width: '100%',
    padding: '5px',
    color: "black",
    background:"#FFCE3E !important",
    '&:active': {
        boxShadow: "none",
    },
    '&:focus': {
        border: "1px solid black",
    },
})

export const CustomDarkButton = styled(Button)({
    boxShadow: "0px 4px 0px #000000",
    border: "1px solid black",
    color: "black",
    background: theme.palette.primary.main,
    '&:active': {
        boxShadow: "none",
    },
    '&:focus': {
        border: "1px solid black",
    },
    '&:hover': {
        border: "1px solid black",
        background: theme.palette.primary.main,
    }
})

export const CustomCircleButton = styled(IconButton)({
    boxShadow: "0px 4px 0px #000000",
    border: "1px solid black",
    height: "35px",
    width: "35px"
})